import Aos from "aos";
import React, { useEffect } from "react";

const NotBornInUk = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 800,
      easing: "slide",
      once: true,
      mirror: false,
    });

    return () => {
      Aos.refresh(); // Refresh AOS when the component unmounts
    };
  }, []);

  return (
    <section id="categories" className="about section mt-5">
      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <span>
          Child not born in UK
          <br />
        </span>
        <h2>
          Child not born in the UK but has lived in the UK for 10 years
          <br />
        </h2>
        {/* <!-- <p>Welcome to our website, dedicated to providing advice and assistance to parents and guardians who -->
        <!-- want to register their children as British citizens.</p> --> */}
      </div>
      {/* <!-- End Section Title --> */}

      <div className="container">
        <div className="col-8 offset-lg-2">
          <ul>
            <li>
              Has your child lived in the UK for ten years? The child does not
              have to be born in the U.K.
            </li>
            <li>
              Do you and your child currently have a visa (leave to remain) in
              the United Kingdom?
            </li>
            <li>Is your child still under the age of 18 years old?</li>
            <li>
              Is your child of good character? Never been in trouble with the
              police.
            </li>
            <li>
              Has your child has spent less than 90 days per year outside the
              United Kingdom over the past ten years?
            </li>
            <li>
              Do the child’s parents currently have a visa (leave to remain)?
            </li>
          </ul>
        </div>

        <div className="col-8 offset-lg-2">
          <p>
            If you have answered yes to all the above questions, then we can
            help register your child as a British Citizen. It does not matter if
            you have only been granted a visa recently and lived without a visa
            before that time. We will be able to register your child as British.
            We have helped hundreds of children register as British Children who
            were under the same criteria.
          </p>

          <p>
            Our team of experienced professionals will handle every aspect of
            the process and will ensure that your application is complete and
            accurate. We will also represent you in communications with the Home
            Office and will provide ongoing support and assistance throughout
            the process.
          </p>

          <p>
            By choosing our services, you can save yourself time, stress, and
            hassle, and can have the peace of mind of knowing that your
            application is in the hands of experienced professionals who will do
            everything possible to ensure a successful outcome.{" "}
            <a href="index.html#contact">Contact us</a>
            today to learn more about how we can help with the registration of
            your child as a British citizen. Please contact us today for a free
            consultation and see how we can help.
          </p>
        </div>
      </div>
    </section>
  );
};

export default NotBornInUk;
