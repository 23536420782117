import Aos from "aos";
import React, { useEffect } from "react";

const BornInUkParentLeave = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 800,
      easing: "slide",
      once: true,
      mirror: false,
    });

    return () => {
      Aos.refresh(); // Refresh AOS when the component unmounts
    };
  }, []);

  return (
    <section id="categories" class="about section mt-5">
      {/* <!-- Section Title --> */}
      <div class="container section-title" data-aos="fade-up">
        <span>
          Born in the UK
          <br />
        </span>
        <h2>
          Child Born in the UK and one parent has now been granted Indefinite
          Leave to Remain
          <br />
        </h2>
        {/* <!-- <p>Welcome to our website, dedicated to providing advice and assistance to parents and guardians who -->
          <!-- want to register their children as British citizens.</p> --> */}
      </div>
      {/* <!-- End Section Title --> */}

      <div class="container">
        <div class="col-8 offset-lg-2">
          <p>
            It may also be possible to register your child are British if the
            child was born in the UK and one of the child’s parents have now
            been granted Indefinite Leave to Remain. It does not matter how long
            the child has lived in the U.K. since their birth, whether it is one
            month or 16 years.
          </p>
          <ul>
            <li>Was your child born in the U.K.?</li>
            <li>
              Has either parent of the child been granted Indefinite Leave to
              Remain in the U.K. since the child’s birth?
            </li>
          </ul>
        </div>

        <div class="col-8 offset-lg-2">
          <p>
            If you answered yes to the above questions, then we can help
            register your child as a British Citizen.
          </p>

          <p>
            Our team of experienced professionals will handle every aspect of
            the process and will ensure that your application is complete and
            accurate. We will also represent you in communications with the Home
            Office and will provide ongoing support and assistance throughout
            the process.
          </p>

          <p>
            By choosing our services, you can save yourself time, stress, and
            hassle, and can have the peace of mind of knowing that your
            application is in the hands of experienced professionals who will do
            everything possible to ensure a successful outcome.{" "}
            <a href="index.html#contact">Contact us</a>
            today to learn more about how we can help with the registration of
            your child as a British citizen.
          </p>

          <p>
            Please contact us today for a free consultation and a free
            assessment of your child’s case and see how we can help.
          </p>
        </div>
      </div>
    </section>
  );
};

export default BornInUkParentLeave;
