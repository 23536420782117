import Aos from "aos";
import React, { useEffect } from "react";

const HeroSection = () => {
  useEffect(() => {
    Aos.init({
      duration: 800,
      easing: "slide",
      once: true,
      mirror: false,
    });

    return () => {
      Aos.refresh(); // Refresh AOS when the component unmounts
    };
  }, []);

  return (
    <section id="home" className="hero section">
      <img src="assets/img/banner-bcr.png" alt="" data-aos="fade-in" />

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row justify-content-start">
          <div className="col-lg-8">
            <p>REGISTER YOUR CHILD AS</p>
            <h2>British Citizen</h2>
            {/* <!-- <p className="col-lg-8">Welcome to our website, dedicated to providing advice and assistance to parents and guardians who
              want to register their children as British citizens.</p> --> */}
            <a href="#about" className="btn-get-started">
              Get Started
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
