import Aos from "aos";
import React, { useEffect } from "react";

const BornInUk = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 800,
      easing: "slide",
      once: true,
      mirror: false,
    });

    return () => {
      Aos.refresh(); // Refresh AOS when the component unmounts
    };
  }, []);
  
  return (
    <section id="categories" className="about section mt-5">
      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <span>
          Born in the UK
          <br />
        </span>
        <h2>
          Child Born in the U.K. and lived in the U.K. for Ten years
          <br />
        </h2>
        {/* <!-- <p>Welcome to our website, dedicated to providing advice and assistance to parents and guardians who -->
        <!-- want to register their children as British citizens.</p> --> */}
      </div>
      {/* <!-- End Section Title --> */}

      <div className="container">
        <div className="col-8 offset-lg-2">
          <p>
            If your child was born in the United Kingdom and has lived in the
            U.K. for at least ten years, then it may be possible to register
            your child as British.
          </p>
          <ul>
            <li>Was your child born in the U.K.?</li>
            <li>Have they lived in the U.K. for at least Ten years?</li>
            <li>
              Have they travelled out of the U.K. for less than 90 days per year
              throughout these ten years?
            </li>
            <li>Are they of good character and stayed out of trouble?</li>
          </ul>
        </div>

        <div className="col-8 offset-lg-2">
          <p>
            If you answered yes to all these questions, then we help register
            your child as a British Citizen. we have successfully registered
            many children under this category. If you require assistance under
            this category, then we can help you.
          </p>

          <p>
            Our team of experienced professionals will handle every aspect of
            the process and will ensure that your application is complete and
            accurate. We will also represent you in communications with the Home
            Office and will provide ongoing support and assistance throughout
            the process.
          </p>

          <p>
            By choosing our services, you can save yourself time, stress, and
            hassle, and can have the peace of mind of knowing that your
            application is in the hands of experienced professionals who will do
            everything possible to ensure a successful outcome.{" "}
            <a href="index.html#contact">Contact us</a>
            today to learn more about how we can help with the registration of
            your child as a British citizen.
          </p>

          <p>
            Please contact us today for a free consultation and a free
            assessment of your child’s case and see how we can help.
          </p>
        </div>
      </div>
    </section>
  );
};

export default BornInUk;
