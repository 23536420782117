import Aos from "aos";
import React, { useEffect } from "react";

const OtherCategories = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 800,
      easing: "slide",
      once: true,
      mirror: false,
    });

    return () => {
      Aos.refresh(); // Refresh AOS when the component unmounts
    };
  }, []);

  return (
    <section id="categories" class="about section mt-5">
      {/* <!-- Section Title --> */}
      <div class="container section-title" data-aos="fade-up">
        <span>
          Other categories
          <br />
        </span>
        <h2>
          Other categories
          <br />
        </h2>
      </div>
      {/* <!-- End Section Title --> */}

      <div class="container">
        <div class="col-8 offset-lg-2">
          <p>
            There are other circumstances where your child would be eligible to
            be registered under discretionary powers by the SSHD. This could be
            where the child was born outside the U.K. and one of the parents has
            now become a British Citizen, and the other parent has Indefinite
            Leave to Remain, or there are other circumstances where you feel
            that your child be registered as a British Citizen. These cases are
            slightly more complex and require further information.
          </p>

          <p>
            If you answered yes to the above questions, then we can help
            register your child as a British Citizen.
          </p>
          <p>
            Our team of experienced professionals will handle every aspect of
            the process and will ensure that your application is complete and
            accurate. We will also represent you in communications with the Home
            Office and will provide ongoing support and assistance throughout
            the process.
          </p>
          <p>
            By choosing our services, you can save yourself time, stress, and
            hassle, and can have the peace of mind of knowing that your
            application is in the hands of experienced professionals who will do
            everything possible to ensure a successful outcome.{" "}
            <a href="index.html#contact">Contact us</a>
            today to learn more about how we can help with the registration of
            your child as a British citizen.
          </p>
          <p>
            Please contact us today for a free consultation and a free
            assessment of your child’s case and see how we can help.
          </p>
        </div>
      </div>
    </section>
  );
};

export default OtherCategories;
