import React from "react";

const Services = () => {
  return (
    <section id="services" className="stats section">
      <div
        className="container section-title"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <span>
          Services
          <br />
        </span>
        <h2>
          Services
          <br />
        </h2>
        {/* <!-- <p>Welcome to our website, dedicated to providing advice and assistance to parents and guardians who -->
        <!-- want to register their children as British citizens.</p> --> */}
      </div>
      {/* <!-- End Section Title --> */}

      <div className="container">
        <div className="row gy-4 align-items-center justify-content-between">
          {/* <!-- <div className="col-lg-5">
          <img src="assets/img/banner.png" alt="" className="img-fluid">
        </div> --> */}

          <div className="col-lg-12">
            {/* <!-- <h3 className="fw-bold fs-2 mb-3">Voluptatem dignissimos provident quasi</h3> --> */}
            <p>
              Our firm can provide assistance to parents, guardians, and social
              workers who want to register their children as British citizens.
              We can help by providing information on the eligibility criteria,
              the steps involved in the registration process, and the documents
              and evidence that will be required.
            </p>

            <p>
              By choosing our services, you can save yourself time, stress, and
              hassle, and have the peace of mind knowing that your application
              is in the hands of experienced professionals who will do
              everything possible to ensure a successful outcome.
            </p>

            <div className="row gy-4">
              <div className="col-lg-4">
                <div className="stats-item d-flex">
                  <i className="bi bi-list-columns-reverse"></i>
                  <div>
                    <p>
                      Providing detailed information on eligibility criteria.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- End Stats Item --> */}

              <div className="col-lg-4">
                <div className="stats-item d-flex">
                  <i className="bi bi-bar-chart"></i>
                  <div>
                    <p>
                      Guiding you through the steps involved in the registration
                      process.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- End Stats Item --> */}

              <div className="col-lg-4">
                <div className="stats-item d-flex">
                  <i className="bi bi-file-earmark-check"></i>
                  <div>
                    <p>
                      Assisting with the preparation and collation of necessary
                      documents and evidence.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- End Stats Item --> */}

              <div className="col-lg-4">
                <div className="stats-item d-flex">
                  <i className="bi bi-check-square"></i>
                  <div>
                    <p>
                      Completing and submitting the application form on your
                      behalf.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- End Stats Item --> */}

              <div className="col-lg-4">
                <div className="stats-item d-flex">
                  <i className="bi bi-arrow-up-square"></i>
                  <div>
                    <p>
                      Uploading supporting documentation and evidence to the
                      Home Office.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- End Stats Item --> */}

              <div className="col-lg-4">
                <div className="stats-item d-flex">
                  <i className="bi bi-headset"></i>
                  <div>
                    <p>
                      Offering ongoing support and assistance throughout the
                      process.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!-- End Stats Item --> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
