import React from "react";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <section id="categories" className="advertisers-service-sec pt-5 pb-5">
      <div className="container">
        <div className="row">
          {/* <!-- Section Title --> */}
          <div className="container section-title" data-aos="fade-up">
            <span>
              Categories
              <br />
            </span>
            <h2>
              Categories
              <br />
            </h2>
          </div>
        </div>
        <div className="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <i className="bi bi-list-columns-reverse"></i>
              </div>
              <h3>
                Child not born in the UK but has lived in the UK for 10 years
              </h3>
              <p>
                We have helped hundreds of children register as British Children
                who were under the same criteria.
              </p>
              <Link
                to="/not-born-in-uk"
                className="btn btn-outline btn-categories"
              >
                Read More
              </Link>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <i className="bi bi-list-columns-reverse"></i>
              </div>
              <h3>
                Double Descent - Register a child as a British Citizen by
                Descent
              </h3>
              <p>
                We have successfully registered many children born abroad as
                British Citizens, to a parent who themselves were a British
                Citizen by descent.
              </p>
              <Link
                to="/double-descent"
                className="btn btn-outline btn-categories"
              >
                Read More
              </Link>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <i className="bi bi-list-columns-reverse"></i>
              </div>
              <h3>
                Child Born in the U.K. and lived in the U.K. for Ten years
              </h3>
              <p>
                We have successfully registered many children under this
                category who were born and lived in the U.K. for a Ten year
                period.
              </p>
              <Link
                to="/born-in-uk"
                className="btn btn-outline btn-categories"
              >
                Read More
              </Link>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <i className="bi bi-list-columns-reverse"></i>
              </div>
              <h3>
                Child Born in the UK and one parent has now been granted
                Indefinite Leave to Remain
              </h3>
              <p>
                It may also be possible to register your child are British if
                the child was born in the UK and one of the child’s parents have
                now been granted Indefinite Leave to Remain.
              </p>
              <Link
                to="/born-in-uk-parent-leave"
                className="btn btn-outline btn-categories"
              >
                Read More
              </Link>
            </div>
          </div>
          <div className="col">
            <div className="service-card">
              <div className="icon-wrapper">
                <i className="bi bi-list-columns-reverse"></i>
              </div>
              <h3>Other categories</h3>
              <p>
                The child was born outside the U.K. and one of the parents has
                now become a British Citizen, and the other parent has
                Indefinite Leave to Remain, or there are other circumstances
                where you feel that your child be registered as a British
                Citizen.
              </p>
              <Link
                to="/other-categories"
                className="btn btn-outline btn-categories"
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Categories;
