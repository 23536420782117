import Aos from "aos";
import React, { useEffect } from "react";

const DoubleDecent = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 800,
      easing: "slide",
      once: true,
      mirror: false,
    });

    return () => {
      Aos.refresh(); // Refresh AOS when the component unmounts
    };
  }, []);

  return (
    <section id="categories" className="about section mt-5">
      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <span>
          Double Descent
          <br />
        </span>
        <h2>
          Double Descent - Register a child as a British Citizen by Descent
          <br />
        </h2>
        {/* <!-- <p>Welcome to our website, dedicated to providing advice and assistance to parents and guardians who -->
        <!-- want to register their children as British citizens.</p> --> */}
      </div>
      {/* <!-- End Section Title --> */}

      <div className="container">
        <div className="col-8 offset-lg-2">
          <ul>
            <li>
              As a parent of a child, are you a British Citizen by descent?
            </li>
            <li>Was your child born abroad?</li>
            <li>
              Did you as a parent, live in the U.K. for a period of three years
              before your child was born abroad?
            </li>
            <li>
              During these three years, did you spend less than 270 days outside
              the U.K.?
            </li>
            <li>Is your child still under 18?</li>
          </ul>
        </div>

        <div className="col-8 offset-lg-2">
          <p>
            If you answered yes to all these questions, then we help register
            your child as a British Citizen. we have successfully registered
            many children born abroad as British Citizens, to a parent who
            themselves were a British Citizen by descent. This is known as the
            double descent. If you require assistance under this category, then
            we can help you.
          </p>

          <p>
            Our team of experienced professionals will handle every aspect of
            the process and will ensure that your application is complete and
            accurate. We will also represent you in communications with the Home
            Office and will provide ongoing support and assistance throughout
            the process.
          </p>

          <p>
            By choosing our services, you can save yourself time, stress, and
            hassle, and can have the peace of mind of knowing that your
            application is in the hands of experienced professionals who will do
            everything possible to ensure a successful outcome.{" "}
            <a href="index.html#contact">Contact us</a>
            today to learn more about how we can help with the registration of
            your child as a British citizen.
          </p>

          <p>
            Please contact us today for a free consultation and a free
            assessment of your child’s case and see how we can help.
          </p>
        </div>
      </div>
    </section>
  );
};

export default DoubleDecent;
