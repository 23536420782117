import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  return (
    <header id="header" className="header fixed-top">
      {/* <!-- <div className="topbar d-flex align-items-center">
      <div className="container d-flex justify-content-center justify-content-md-between">
        <div className="contact-info d-flex align-items-center">
          <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:contact@example.com">contact@example.com</a></i>
          <i className="bi bi-phone d-flex align-items-center ms-4"><span>+1 5589 55488 55</span></i>
        </div>
        <div className="social-links d-none d-md-flex align-items-center">
          <a href="#" className="twitter"><i className="bi bi-twitter-x"></i></a>
          <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
          <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
          <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
        </div>
      </div>
    </div> -->
    <!-- End Top Bar --> */}

      <div className="branding d-flex align-items-center">
        <div className="container position-relative d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            {/* <!-- Uncomment the line below if you also wish to use an image logo --> */}
            {/* <!-- <img className="logo d-flex align-items-center" src="assets/img/logo-only.png" alt=""> --> */}
            <h1 className="sitename">REGISTER BRITISH CHILD</h1>
          </a>

          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <HashLink className="active" to="/#home">
                  Home
                </HashLink>
              </li>
              <li>
                <HashLink to="/#about">About</HashLink>
              </li>
              <li className="dropdown">
                <HashLink to="/#categories">
                  <span>Categories</span>{" "}
                  <i className="bi bi-chevron-down toggle-dropdown"></i>
                </HashLink>
                <ul>
                  <li>
                    <Link to="/not-born-in-uk">
                      Child not born in the UK but has lived in the UK for 10
                      years
                    </Link>
                  </li>
                  <li>
                    <Link to="/double-descent">
                      Double Descent - Register a child as a British Citizen by
                      Descent
                    </Link>
                  </li>
                  <li>
                    <Link to="/born-in-uk">
                      Child Born in the U.K. and lived in the U.K. for Ten years
                    </Link>
                  </li>
                  <li>
                    <Link to="/born-in-uk-parent-leave">
                      Child Born in the UK and one parent has now been granted
                      Indefinite Leave to Remain
                    </Link>
                  </li>
                  <li>
                    <Link to="/other-categories">Other categories</Link>
                  </li>
                </ul>
              </li>
              <li>
                <HashLink to="/#services">Services</HashLink>
              </li>
              <li>
                <HashLink to="/#team">Team</HashLink>
              </li>
              <li>
                <HashLink to="/#faq">FAQ</HashLink>
              </li>
              <li>
                <HashLink to="/#contact">Contact</HashLink>
              </li>
            </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
