import React from "react";

const Teams = () => {
  return (
    <section id="team" className="about section">
      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <span>
          Our Team
          <br />
        </span>
        <h2>
          Who we are?
          <br />
        </h2>
        {/* <!-- <p>Welcome to our website, dedicated to providing advice and assistance to parents and guardians who -->
        <!-- want to register their children as British citizens.</p> --> */}
      </div>
      {/* <!-- End Section Title --> */}

      <div className="container">
        <div className="row gy-4">
          <div
            className="col-lg-4 order-1 order-lg-2"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img src="assets/img/servies.png" className="img-fluid" alt="" />
          </div>

          <div
            className="col-lg-8 order-2 order-lg-1 content"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {/* <!-- <h3>Voluptatem dignissimos provident quasi corporis</h3> --> */}

            <ul className="mx-5">
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>
                  Our dedicated team of experienced professionals is here to
                  assist you at every step of the process. With a deep
                  understanding of the complexities involved in registering a
                  child as a British citizen, our team is equipped to handle all
                  aspects of your application with precision and care.
                </span>
              </li>
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>
                  We pride ourselves on our commitment to providing excellent
                  service and support to our clients. From the initial
                  consultation to the successful registration of your child as a
                  British citizen, our team will be with you every step of the
                  way, ensuring that your application is complete, accurate, and
                  handled with the utmost professionalism.
                </span>
              </li>
              <li>
                <i className="bi bi-check-circle"></i>{" "}
                <span>
                  Contact us today to learn more about how we can help with the
                  registration of your child as a British citizen.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Teams;
