import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <footer id="footer" class="footer position-relative">
      <div class="container footer-top">
        <div class="row gy-4">
          <div class="col-lg-6 col-md-6">
            <div class="footer-about">
              <a href="/" class="logo sitename">
                REGISTER BRITISH CHILD
              </a>
              <div class="footer-contact pt-3">
                <p>Hunter Stone Law, 518 Roman Road,</p>
                <p>London, E3 5ES</p>
                <p class="mt-3">
                  <strong>Phone:</strong> <span>+44 20 3137 9097</span>
                </p>
                <p>
                  <strong>Email:</strong>{" "}
                  <span>info@registeryourchildbritish.com</span>
                </p>
              </div>
              <div class="social-links d-flex mt-4">
                <a href="">
                  <i class="bi bi-twitter-x"></i>
                </a>
                <a href="">
                  <i class="bi bi-facebook"></i>
                </a>
                <a href="">
                  <i class="bi bi-instagram"></i>
                </a>
                <a href="">
                  <i class="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-md-3 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li>
                <HashLink to="/#home">Home</HashLink>
              </li>
              <li>
                <HashLink to="/#about">About</HashLink>
              </li>
              <li>
                <HashLink to="/#categories">Categories</HashLink>
              </li>
              <li>
                <HashLink to="/#services">Services</HashLink>
              </li>
              <li>
                <HashLink to="/#team">Team</HashLink>
              </li>
              <li>
                <HashLink to="/#faq">FAQ</HashLink>
              </li>
              <li>
                <HashLink to="/#contact">Contact</HashLink>
              </li>
            </ul>
          </div>

          <div class="col-lg-4 col-md-3 footer-links">
            <h4>Our Categories</h4>
            <ul>
              <li>
                <Link to="/not-born-in-uk">
                  Child not born in the UK but has lived in the UK for 10 years
                </Link>
              </li>
              <li>
                <Link to="/double-descent">
                  Double Descent - Register a child as a British Citizen by
                  Descent
                </Link>
              </li>
              <li>
                <Link to="/born-in-uk">
                  Child Born in the U.K. and lived in the U.K. for Ten years
                </Link>
              </li>
              <li>
                <Link to="/born-in-uk-parent-leave">
                  Child Born in the UK and one parent has now been granted
                  Indefinite Leave to Remain
                </Link>
              </li>
              <li>
                <Link to="/other-categories">Other categories</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="container copyright text-center mt-4">
        <p>
          © <span>Copyright</span>{" "}
          <strong class="px-1 sitename">Register Your Child As British</strong>{" "}
          <span>All Rights Reserved</span>
        </p>
        <div class="credits">
          <strong class="sitename">registeryourchildasbritish.com</strong> is a
          collaboration between MK Squared Marketing and Hunter Stone Law.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
