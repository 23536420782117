import React from "react";

const FrequentlyAsked = () => {
  return (
    <section id="faq" className="faq-section section">
      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <span>
          Frequently Asked
          <br />
        </span>
        <h2>
          Frequently Asked
          <br />
        </h2>
      </div>
      {/* <!-- End Section Title --> */}
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="accordion accordion-flush" id="accordionFlushFAQ">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What is the eligibility criteria for registering a child as
                    a British citizen?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushFAQ"
                >
                  <div className="accordion-body">
                    To be eligible to register as a British citizen, a child
                    must have either been born in the United Kingdom, or must
                    have lived in the United Kingdom for at least ten years. The
                    child must also have valid leave to remain in the United
                    Kingdom, and must meet any other relevant eligibility
                    criteria.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    What documents and evidence are required for a child&#39;s
                    application for British citizenship?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushFAQ"
                >
                  <div className="accordion-body">
                    The specific documents and evidence required for a
                    child&#39;s application for British citizenship will vary
                    depending on the individual circumstances of the child and
                    their parents or guardians. In general, you will need to
                    provide proof of the child&#39;s birth and residence in the
                    United Kingdom, as well as proof of your own leave to remain
                    in the UK. You will also need to provide passport-style
                    photographs of the child and pay a fee for the application.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Can your firm help with the process of registering a child
                    as a British citizen?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushFAQ"
                >
                  <div className="accordion-body">
                    Yes, our firm can provide assistance with the process of
                    registering a child as a British citizen. We can provide
                    information and guidance on the eligibility criteria, the
                    steps involved in the registration process, and the
                    documents and evidence that will be required. We can also
                    provide assistance with completing the application form and
                    with gathering and organizing the necessary documents and
                    evidence. In addition, we can represent you in
                    communications with the relevant government authorities, and
                    can provide ongoing support and assistance throughout the
                    process.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    What are the advantages of successfully registering a child
                    as a British citizen?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushFAQ"
                >
                  <div className="accordion-body">
                    There are several advantages to successfully registering a
                    child as a British citizen. These include the right to live
                    and work in the UK without the need for a visa, the right to
                    access public services such as healthcare and education, and
                    the ability to travel to other countries without the need
                    for a visa. Being a British citizen can also provide a sense
                    of belonging and identity, and can make it easier for the
                    child to pursue higher education in the UK.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrequentlyAsked;
