import React from "react";

const About = () => {
  return (
    <section id="about" className="about section">
      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <span>
          About Us
          <br />
        </span>
        <h2>
          About Us
          <br />
        </h2>
        {/* <!-- <p>Welcome to our website, dedicated to providing advice and assistance to parents and guardians who -->
        <!-- want to register their children as British citizens.</p> --> */}
      </div>
      {/* <!-- End Section Title --> */}

      <div className="container">
        <div className="row gy-4">
          <div
            className="col-lg-5 order-1 order-lg-2"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img src="assets/img/banner.png" className="img-fluid" alt="" />
          </div>

          <div
            className="col-lg-7 order-2 order-lg-1 content"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {/* <!-- <h3>Voluptatem dignissimos provident quasi corporis</h3> --> */}
            <p className="mx-5">
              Welcome to our website, dedicated to providing advice and
              assistance to parents and guardians who want to register their
              children as British citizens. We understand that the process of
              registering a child as a British citizen can be complex and
              daunting, which is why we have a dedicated team of experienced
              professionals to help you through the process to ensure your
              application is successful.
            </p>
            <p className="mx-5">
              We are committed to providing accurate, up-to-date information and
              assistance to help make the process of registering a child as a
              British citizen as smooth and straightforward as possible. Our
              goal is to make the process of registering a child as a British
              citizen as simple and stress-free as possible, and we will do
              everything we can to help you achieve that goal.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
